// Fuentes
$fuente-principal:  'Roboto', sans-serif;



$telefono: 480px;
$tablet: 850px;
$desktop: 1024px;
$xl_desktop: 1200px;

// Colores
$color-principal: #e1eef4 ; 
$azul-claro: #54c5f8;
$azul-oscuro: #01579b;

$color-barra: #08273f;
// $color-nav: #1d3649;
$color-nav: #111926;

$color-line: #bec1bf;

$negro: #1a1b15;
$blanco: #FFFFFF;
$rojo-fuerte: #ff0005;
$rojo-claro: #faeded;
$boton-calculo: #e9a414;
$boton-azul: #31b9f6;
$boton-verde: #31d880;

// Colores de Estatus
$status-act: #e1f7e9;
$status-inac: #e5e6ea;
$status-pnd: #ffe8cc;
$status-ctz: #d7e7ff;
$status-svc: #ffcf5c;
$status-cmp: #e1f7e9;
$status-man: #45a2ff;
$status-can: #ffd9db;
$status-rep: #f0e4ff;

// Fuentes
$delgada: 300;
$regular: 400;
$bold: 700;
$black: 900
