@use 'base/variables' as v;


.ag-center-cols-container .estatus {

    display: flex;
    justify-content: center;
    align-items: center;

    & span {
        border-radius: 100px;
        line-height: 1.6;
        font-weight: 500;
        border: 1.5px solid rgba(91, 91, 91, .1);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    & .ag-select {
        width: 100px;
        padding: 3px 7px;

        .ag-wrapper {
            border-radius: 100px;
            font-weight: 500;
            box-shadow: none;
            border: 1.5px solid rgba(91, 91, 91, .1);
        }
    }

    &-act span {
        background-color: v.$status-act;
        color: darken(v.$status-act, 30%);
    }

    &-inac span {
        background-color: v.$status-inac;
        color: darken(v.$status-inac, 30%);
    }

    &-man span {
        background-color: v.$status-man;
        color: darken(v.$status-man, 30%);
    }

    &-pnd span {
        background-color: v.$status-pnd;
        color: darken(v.$status-pnd, 30%);
    }

    &-ctz span {
        background-color: v.$status-ctz;
        color: darken(v.$status-ctz, 30%);
    }

    &-svc span {
        background-color: v.$status-svc;
        color: darken(v.$status-svc, 30%);
    }

    &-cmp span {
        background-color: v.$status-cmp;
        color: darken(v.$status-cmp, 30%);
    }

    &-can span {
        background-color: v.$status-can;
        color: darken(v.$status-can, 30%);
    }

    &-rep span {
        background-color: v.$status-rep;
        color: darken(v.$status-rep, 30%);
    }

}