@use 'base/variables' as v;
@use 'base/mixins' as m;

#seccion_totales {
    padding-bottom: 2rem;

    .seccion_totales-contenedor {
        background-color: #f4f5f8;
        padding: 0.5rem;
        gap: 2rem;
    }

    .totales_contenedor {

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 3rem;

        .titulo {
            display: flex;
            height: 100%;
            justify-content: left;
            align-items: center;
            padding-left: 1rem;
        }

        &-title {
            text-align: left;
            font-size: 14px;
            font-weight: 600;
        }

        &-costo {
            margin-top: 4px;

            input {
                padding: 0;
                text-align: right;
                border: none;
                outline: none;
                box-shadow: none;
                background-color: transparent;
            }
        }

    }
}

.editar_bitacora {

    .srchPad {
        margin: .5rem 0;
    }

    .gran_total {
        display: flex;
        justify-content: flex-end;
    }

    .gran_total-costo {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        background: #f4f4f4;
        padding: .5rem 1rem;
        border-radius: 15px;

        input {
            border: none;
            width: auto;
            text-align: right;
            background-color: transparent;

            &:focus {
                box-shadow: none;
            }
        }
    }

}
 

.fab {
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;

    &_cont {
        position: absolute;
        right: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        overflow: hidden;

        &-column {
            background-color: pink;
            display: flex;
            height: 100%;
            width: 100%;
        }
    }

    &_icon {
        i {
            color: #111926;
            font-size: 34px;
            transition: color .5s ease;

            &:hover {
                color: lighten(#111926, 20%);
            }

            &:active {
                color: lighten(#111926, 30%);
            }
        }
    }

    &_list {
        width: -webkit-fill-available;
        height: 100%;
        visibility: hidden;
        background-color: #f4f4f4;
        padding: 0 1rem;
        border-radius: 10px;

        ul {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            height: 100%;
            gap: 1rem;
            transform: translateX(100%);
            transition: transform 0.5s ease;

            li {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                gap: 5px;

                p {
                    font-size: 12px;
                    font-family: v.$fuente-principal;
                }
            }

        }
    }

    ul.activo {
        transform: translateX(0%); // Desplaza la lista fuera de la vista
    }

    .fab_list.invisible {
        visibility: inherit;
    }

}

.botones_filtro{
    align-content: center;

    .quickFilterContent  .form-dsg{
        margin: 1.3rem 2rem;
    }
    &-periodo{
        width: 9rem;
        position: relative;
    }
}