.ag-theme-balham {
    input {
        border-radius: 0;
    }
}

.gridInputFile {

    input[type=file] {
        visibility: hidden;
        padding: 0;
    }

    input[type=file]::file-selector-button {
        visibility: visible;
        min-width: 8rem;
    }

}

.myGridSinFondo {

    .ag-root-wrapper {
        border: none;
    }

    .ag-header, .ag-advanced-filter-header{
        background-color: transparent;
        border-bottom: none;
    }
}